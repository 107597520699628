/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearcom-unveils-unprecedented-next-generation-hearing-aid-horizon-ix",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearcom-unveils-unprecedented-next-generation-hearing-aid-horizon-ix",
    "aria-label": "hearcom unveils unprecedented next generation hearing aid horizon ix permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "hear.com unveils unprecedented next-generation hearing aid, Horizon IX"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/",
    className: "c-md-a"
  }, "hear.com"), " won two significant awards in the 2023 BIG Innovation Awards presented by the Business Intelligence Group. Organizations from across the globe submitted their recent innovations for consideration in the BIG Innovation Awards. Nominations were then judged by a select group of business leaders and executives who volunteer their time and expertise to score submissions. hear.com, innovation leader in online hearing care and fastest growing online hearing care company in the last 10 years globally, has won for its ", React.createElement(_components.a, {
    href: "/hearing-aids/horizon/go-ix/",
    className: "c-md-a"
  }, "Horizon Go IX hearing aids"), ", cutting edge hearing aids providing a one-of-a-kind sound experience and speech clarity, and its ", React.createElement(_components.a, {
    href: "/teleaudiology/",
    className: "c-md-a"
  }, "Tele-Audiology Clinic-in-a-Box™ solution"), ", a breakthrough and unique online approach to hearing care that provides customers with medical-grade hearing care within the comfort of their home."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "hear.com's Horizon IX hearing aids are a first in the industry to be developed jointly by experts, audiologists, and engineers, using data from millions of hearing consultations with customers to address the needs of younger, more active people with hearing loss. With hear.com's Horizon IX's stylish design and unique features including speech focus, relax mode, and panorama effect, customers can customize their hearing experience and find the perfect model for their lifestyle, allowing them to be more active in conversations and complex hearing situations around them."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "hear.com recognizes and is addressing the massive barriers to hearing care including the perceived stigma, lack of access and affordability. The company is proud to offer a Tele-Audiology Clinic-in-a-Box™ solution, providing customers with access to hand-picked experienced hearing care professionals in their high-quality network. hear.com's Tele-Audiology service uses the same equipment as an in-person clinic remotely, and customers receive the care they need with the convenience of being served at home."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "\"We are honored that hear.com has been selected as a double-winner of the 2023 BIG Innovation Award for our Horizon Go IX hearing aids and our Tele-Audiology Clinic-in-a-Box™ solution,\" says Paul Crusius, Co-CEO and Co-Founder. \"We believe that everyone should hear well to live well, which is why we're proud to offer these innovative solutions for our customers so that they can get high quality, medical-grade hearing care anytime, anywhere and continue to live their lives to the fullest.\""), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "\"Innovation is 'business critical' in today's society,\" said Maria Jimenez, chief operating officer of the Business Intelligence Group. \"We are thrilled to be honoring hear.com as they are leading by example and improving the lives of so many.\""), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "about-hearcom",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#about-hearcom",
    "aria-label": "about hearcom permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "About hear.com"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "hear.com is a global innovator in online hearing care with a unique digital approach recognized for its state-of-the-art solutions for customers and the largest online retailer of medical-grade hearing aids globally. Founded in Germany in 2012 and headquartered in Miami, hear.com has helped millions of people get on the path to better hearing by guiding customers through the hearing care process and educating them on hearing aid technology, styles, prices and more. hear.com was named a top customer service company by Newsweek in 2021 and has been featured in publications like New York Times, WebMD, Forbes, and Business Insider."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "about-business-intelligence-group",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#about-business-intelligence-group",
    "aria-label": "about business intelligence group permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "About Business Intelligence Group"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Business Intelligence Group was founded with the mission of recognizing true talent and superior performance in the business world. Unlike other industry award programs, these programs are judged by business executives having experience and knowledge. The organization's proprietary and unique scoring system selectively measures performance across multiple business domains and then rewards those companies whose achievements stand above those of their peers."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Source: ", React.createElement("a", {
    href: "https://www.bintelligence.com/blog/2023/1/11/11-executives-55-companies-and-91-products-win-2023-big-innovation-award",
    target: "_blank"
  }, "Business Intelligence Group Announces The Winners of the 2023 Big Innovation Awards")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
